import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Col, Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import IntlTelInput from "react-intl-tel-input";

import AnalyticsContext from "../../services/analyticsContext";
import AuthContext from "../../services/authContext";
import dispatcher from "../../Scripts/ReduxDispatcher";
import ToastAlert from "../ToastAlert";

import "react-intl-tel-input/dist/main.css";

const SignUp = (props) => {
  const analyticsService = useContext(AnalyticsContext);
  const authService = useContext(AuthContext);

  const {
    alertMessage,
    alertVariant,
    episodeNotLive,
    handleChangeToQueueClosed,
    handleChangeToSignInPage,
    privacy_link: privacyLink,
    terms_link: termsLink,
    template,
    uidData,
  } = props;

  const validationSchema = yup.object().shape({
    givenName: yup.string().trim().required("A first name is required."),
    familyName: yup.string().trim().required("A last name is required."),
    email: yup.string().email().trim().required("An email is required."),
    password: yup
      .string()
      .trim()
      .required("A password is required.")
      .min(8, template.messages.passwordLength),
    confirmPassword: yup
      .string()
      .trim()
      .required("Please confirm password.")
      .oneOf(
        [yup.ref("password"), null],
        template.messages.nonMatchingPasswords
      ),
    checkbox: yup.bool().oneOf([true], "Must agree to terms and conditions."),
  });

  const {
    errors: yupErrors,
    handleSubmit,
    register,
  } = useForm({
    validationSchema,
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(undefined);
  let errors = { ...yupErrors };

  if (phoneNumberError)
    errors = { ...errors, phoneNumber: { message: phoneNumberError } };

  useEffect(() => {
    dispatcher.setCurrentFocus("SignUpForm");
    analyticsService.handlePageViewChange("NCP_NIQ_Create");
    dispatcher.setIsSigningUp(true);

    // component will unmount
    return () => dispatcher.setIsSigningUp(false);
  }, [analyticsService]);

  const onSubmit = (data) => {
    const { givenName, familyName, email, password } = data;
    const signupInfo = {
      givenName,
      familyName,
      email,
      password,
      uidData,
      signupLink: window.location.toString(),
      termsLink,
      privacyLink,
      phoneNumber,
    };

    authService.signup(signupInfo).subscribe(
      ({ data }) => {
        dispatcher.setAlert({
          success: true,
          response: template.messages.accountCreated,
        });
        if (episodeNotLive) {
          handleChangeToQueueClosed();
        } else {
          if (handleChangeToSignInPage) {
            handleChangeToSignInPage();
          }
        }
      },
      (err) =>
        dispatcher.setAlert({
          success: false,
          response: template.messages.errorGeneric + " " + err,
        })
    );
  };

  const handlePhoneNumberEntry = (status, phoneNumber) => {
    if (status) {
      phoneNumber = phoneNumber.toString().match(/\d/g).join("");
      setPhoneNumber("+" + phoneNumber.trim());
    }
    setPhoneNumberError(
      status ? undefined : "Please enter a valid phone number."
    );
  };

  const renderVccTermsAndPrivacyAgreementLinks = () => {
    const textFromTemplate = template.termsAndPrivacyAgreementText;
    const [terms, privacy] = textFromTemplate.split("//REPLACETEXT//");

    return (
      <div
        className="text-muted"
        style={{ fontSize: "1em", fontWeight: "bold", textAlign: "center" }}
      >
        {terms}
        <a href={termsLink} rel="noopener noreferrer" target="_blank">
          {template.termsLinkText}
        </a>
        {privacy}
        <a href={privacyLink} rel="noopener noreferrer" target="_blank">
          {template.privacyLinkText}
        </a>
      </div>
    );
  };

  const hasErrors = Object.entries(errors).length > 0;

  return (
    <Container className="accountContainer">
      <form style={{ flex: "auto" }} onSubmit={handleSubmit(onSubmit)}>
        <div
          className="text-center"
          style={{ fontFamily: "Oswald, sans-serif" }}
        >
          <h2>{template.headline}</h2>
        </div>
        <hr style={{ marginBottom: "30px", marginTop: "30px" }} />
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Control
              isInvalid={errors?.givenName}
              name="givenName"
              placeholder={template.inputs.given_name}
              ref={register}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.givenName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              isInvalid={errors?.familyName}
              name="familyName"
              placeholder={template.inputs.family_name}
              ref={register}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.familyName?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <IntlTelInput
            containerClassName="intl-tel-input"
            defaultValue={phoneNumber}
            inputClassName="form-control form-control-sm"
            placeholder={template.inputs.phoneNumber}
            style={{
              borderColor: phoneNumberError !== undefined ? "red" : "#ced4da",
            }}
            onPhoneNumberChange={(status, value, countryData, numberValue) => {
              handlePhoneNumberEntry(status, numberValue);
            }}
            onSelectFlag={(value, countryData, numberValue, status) => {
              handlePhoneNumberEntry(status, numberValue);
            }}
          />
          {phoneNumberError && (
            <small style={{ color: "red" }}>
              {errors?.phoneNumber?.message}
            </small>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors?.email}
            name="email"
            placeholder={template.inputs.email}
            ref={register}
            type="email"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors?.password}
            name="password"
            placeholder={template.inputs.password1}
            ref={register}
            type="password"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.password?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors?.confirmPassword}
            name="confirmPassword"
            placeholder={template.inputs.password2}
            ref={register}
            type="password"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.confirmPassword?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Check
            isInvalid={errors?.checkbox}
            label={template.inputs.agegateCheckboxText}
            name="checkbox"
            ref={register}
            type="checkbox"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.checkbox?.message}
          </Form.Control.Feedback>
        </Form.Group>
        {renderVccTermsAndPrivacyAgreementLinks()}
        <br />
        <ToastAlert alertMessage={alertMessage} alertVariant={alertVariant} />
        <br />
        <div className="text-center" style={{ marginTop: "15px" }}>
          <ButtonGroup>
            <Button
              variant="outline-primary"
              onClick={() => {
                episodeNotLive
                  ? handleChangeToQueueClosed()
                  : handleChangeToSignInPage();
              }}
            >
              {template.buttons.cancel}
            </Button>
            <Button disabled={hasErrors} type="submit" variant="primary">
              {template.buttons.createAccount}
            </Button>
          </ButtonGroup>
        </div>
      </form>
    </Container>
  );
};

const mapStateToProps = (store, ownProps) => {
  const { alert } = store;

  let alertMessage = "";
  if (typeof alert.response === "string") {
    alertMessage = alert.response;
  } else if (alert.response) {
    alertMessage = alert.response.message;
  }
  const alertVariant = alert && alert.success ? "success" : "danger";

  return {
    alertMessage,
    alertVariant,
    privacy_link: store.legal.vcc_privacy[0].terms_link, // For some reason the server started casting this to an array
    template: store.hostedTemplate.NotInQueueView.SignUpForm,
    terms_link: store.legal.vcc_terms[0].terms_link, // For some reason the server started casting this to an array
    uidData: store.uidData,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(SignUp);
